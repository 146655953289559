<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1200px;" v-if="!isLoading && !isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20240308/pc_bg.jpg" alt="">
            <div class="content">
                <div class="top1Box" v-if="rankList[0]">
                    <img src="../../assets/imgs/activity/20240308/icon_wing.png" alt="" class="iconWing">
                    <img src="../../assets/imgs/activity/20240308/pc_TOP1.png" alt="" class="topBg">
                    <img :src="rankList[0].imgUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[0].nickName }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[0].diamond }}</div>
                    </div>
                </div>
                <div class="d-flex a-center j-center">
                    <div class="top2Box" v-if="rankList[1]">
                        <img src="../../assets/imgs/activity/20240308/pc_TOP2.png" alt="" class="topBg">
                        <img :src="rankList[1].imgUrl" alt="" class="roomImg">
                        <div class="roomName d-overflow">{{ rankList[1].nickName }}</div>
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[1].diamond }}</div>
                        </div>
                    </div>
                    <div class="top3Box" v-if="rankList[2]">
                        <img src="../../assets/imgs/activity/20240308/pc_TOP3.png" alt="" class="topBg">
                        <img :src="rankList[2].imgUrl" alt="" class="roomImg">
                        <div class="roomName d-overflow">{{ rankList[2].nickName }}</div>
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[2].diamond }}</div>
                        </div>
                    </div>
                </div>

                <div class="list" v-if="rankList.length > 3">
                    <div v-for="(item, index) in rankList" :key="index">
                        <div v-if="index >= 3" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                            <div class="sortNum">{{ index + 1 }}</div>
                            <img :src="item.imgUrl" alt="" class="roomImg">
                            <div class="roomName d-overflow flex-1">{{ item.nickName }}</div>
                            <div class="hotBox d-flex a-center j-center">
                                <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                                <div class="hotNumber">{{ item.diamond }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    <div class="title">活动规则</div>
                    <div class="content">
                        <p>
                            1. 活动时间：2024年3月8日至3月10日。<br />
                            2. 活动期间，用户每收到1随心钻价值礼物可获得1点魅力值。<br />
                            3. 魅力值将根据用户收到的礼物价值进行累积。<br />
                            4. 活动结束后，根据用户的魅力值进行排名，并公布排名结果。<br />
                            5. 奖励会在活动结束后14个工作日内发放。
                        </p>
                    </div>
                </div>

                <div class="rewardBox">
                    <div class="title">活动奖励</div>
                    <div class="content">
                        <p>
                            1.排名第1名：获得独家宠爱(5200钻)，新春Q头(30天)，粉色之恋(30天)。<br />
                            2.排名第2名：获得心之声(3344钻)，新春Q头(14天)，粉色之恋(14天)。<br />
                            3.排名第3名：获得四季予你(1314钻)，新春Q头(7天)，粉色之恋(7天)。<br />
                            4.排名第4-10名：获得守护神(520钻)，新春Q头(3天)，粉色之恋(3天)。<br />
                        </p>
                    </div>
                </div>

                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请在公众号“随心开黑”联系在线客服进行咨询。
                </div>
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="!isLoading && isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20240308/h5_bg_top.jpg" alt="">
            <div class="content">
                <div class="top1Box topBox" v-if="rankList[0]">
                    <img src="../../assets/imgs/activity/20240308/icon_wing.png" alt="" class="iconWing">
                    <img src="../../assets/imgs/activity/20240308/pc_TOP1.png" alt="" class="topBg">
                    <img :src="rankList[0].imgUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[0].nickName }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[0].diamond }}</div>
                    </div>
                </div>
                <div class="top2Box topBox" v-if="rankList[1]">
                    <img src="../../assets/imgs/activity/20240308/pc_TOP2.png" alt="" class="topBg">
                    <img :src="rankList[1].imgUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[1].nickName }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[1].diamond }}</div>
                    </div>
                </div>
                <div class="top3Box topBox" v-if="rankList[2]">
                    <img src="../../assets/imgs/activity/20240308/pc_TOP3.png" alt="" class="topBg">
                    <img :src="rankList[2].imgUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[2].nickName }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[2].diamond }}</div>
                    </div>
                </div>

                <div class="list" v-if="rankList.length > 3">
                    <div v-for="(item, index) in rankList" :key="index">
                        <div v-if="index >= 3" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                            <div class="sortNum">{{ index + 1 }}</div>
                            <img :src="item.imgUrl" alt="" class="roomImg">
                            <div class="right">
                                <div class="roomName d-overflow">{{ item.nickName }}</div>
                                <div class="hotBox d-flex a-center">
                                    <img src="../../assets/imgs/activity/20240308/icon_hot.png" alt="" class="iconHot">
                                    <div class="hotNumber">{{ item.diamond }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    <div class="title">活动规则</div>
                    <div class="content">
                        <p>
                            1. 活动时间：2024年3月8日至3月10日。<br />
                            2. 活动期间，用户每收到1随心钻价值礼物可获得1点魅力值。<br />
                            3. 魅力值将根据用户收到的礼物价值进行累积。<br />
                            4. 活动结束后，根据用户的魅力值进行排名，并公布排名结果。<br />
                            5. 奖励会在活动结束后14个工作日内发放。
                        </p>
                    </div>
                </div>

                <div class="rewardBox">
                    <div class="title">活动奖励</div>
                    <div class="content">
                        <p>
                            1.排名第1名：获得独家宠爱(5200钻)，新春Q头(30天)，粉色之恋(30天)。<br />
                            2.排名第2名：获得心之声(3344钻)，新春Q头(14天)，粉色之恋(14天)。<br />
                            3.排名第3名：获得四季予你(1314钻)，新春Q头(7天)，粉色之恋(7天)。<br />
                            4.排名第4-10名：获得守护神(520钻)，新春Q头(3天)，粉色之恋(3天)。<br />
                        </p>
                    </div>
                </div>

                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请在公众号“随心开黑”联系在线客服进行咨询。
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    rankOfWomenDay
} from "@/util/api/api.js"
export default {
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            rankList: [],// 排行榜列表
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        this._rankOfWomenDay()
    },
    methods: {
        // 获取女神节-排行榜
        async _rankOfWomenDay() {
            try {
                let res = await rankOfWomenDay()
                if (res.resultCode == '000000') {
                    console.log(res)
                    if (res.data && res.data.list) {
                        this.rankList = res.data.list
                        this.isLoading = false
                    }
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        }
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #FFC1D4;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .content {
            position: relative;
            z-index: 1;

            .top1Box {
                position: relative;
                margin: 860px auto 0;
                width: 280px;
                padding: 1px;
                box-sizing: border-box;

                .iconWing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 600px;
                    transform: translate(-50%, -68%);
                }

                .topBg {
                    position: absolute;
                    display: block;
                    width: 280px;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 180px;
                    width: 180px;
                    border-radius: 16px;
                    margin: 84px auto 0;
                    object-fit: cover;
                }

                .roomName {
                    position: relative;
                    width: 180px;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin: 35px auto 0;
                }

                .hotBox {
                    position: relative;
                    margin-top: 34px;

                    .iconHot {
                        display: block;
                        height: 48px;
                        width: 42px;
                        margin-right: 11px;
                    }

                    .hotNumber {
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #DA123A;
                    }
                }
            }

            .top2Box,
            .top3Box {
                position: relative;
                margin-top: 2px;
                width: 280px;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 280px;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 180px;
                    width: 180px;
                    border-radius: 16px;
                    margin: 84px auto 0;
                    object-fit: cover;
                }

                .roomName {
                    position: relative;
                    width: 180px;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin: 35px auto 0;
                }

                .hotBox {
                    position: relative;
                    margin-top: 34px;

                    .iconHot {
                        display: block;
                        height: 48px;
                        width: 42px;
                        margin-right: 11px;
                    }

                    .hotNumber {
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #DA123A;
                    }
                }
            }

            .top2Box {
                margin-right: 135px;
            }

            .list {
                width: 1250px;
                height: 980px;
                // overflow-y: scroll;
                margin: 48px auto 0;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 12px;
                    background: #FFE3E9;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 12px;
                    background: #F57891;
                }

                .item {
                    width: 1200px;
                    height: 140px;
                    background: rgba(247, 133, 156, 0.5);

                    &.itemBg {
                        background: #F57891;

                    }

                    .sortNum {
                        width: 72px;
                        height: 72px;
                        line-height: 72px;
                        text-align: center;
                        background: #FFEEF1;
                        border-radius: 48px;
                        font-size: 48px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #441B08;
                        margin-left: 27px;
                    }

                    .roomImg {
                        display: block;
                        width: 100px;
                        height: 100px;
                        border-radius: 16px;
                        margin-left: 32px;
                        object-fit: cover;
                    }

                    .roomName {
                        width: 332px;
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin: 0 32px;
                    }

                    .hotBox {
                        padding-right: 20px;

                        .iconHot {
                            height: 48px;
                            width: 42px;
                            margin-right: 11px;
                        }

                        .hotNumber {
                            font-size: 32px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .ruleBox,
            .rewardBox {
                padding-top: 128px;

                .title {
                    width: 416px;
                    height: 123px;
                    line-height: 103px;
                    text-align: center;
                    background: #F57891;
                    border-radius: 62px;
                    border: 10px solid #FFD9E5;
                    margin: 0 auto;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 64px;
                    color: #FFFFFF;
                    font-style: normal;
                    box-sizing: border-box;
                }

                .content {
                    margin: 29px auto 0;
                    width: 1200px;
                    background: #F57891;
                    border-radius: 51px;
                    border: 10px solid #FFD9E5;
                    padding: 82px 66px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 32px;
                    color: #FFFFFF;
                    line-height: 60px;
                    font-style: normal;
                    box-sizing: border-box;
                }
            }

            .rewardBox {
                padding-top: 84px;
            }

            .descBox {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #280202;
                margin: 0 auto;
                text-align: center;
                padding-top: 110px;
                padding-bottom: 150px;
            }
        }



        .noActivityBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .popBox {
                padding-top: 35px;
                width: 405px;
                height: 191px;
                background: #463B50;
                border-radius: 23px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 467px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 50px;
                    width: 40px;
                    display: block;
                    margin-right: 18px;
                }

                .text {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 42px;
                    width: 102px;
                    background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 35px auto 0;
                    cursor: pointer;
                }
            }

        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 100vw;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

        .content {
            padding-top: 1px;
            margin-top: 56vw;
            position: relative;
            background: url(../../assets/imgs/activity/20240308/h5_bg_main.jpg)repeat-y;
            background-size: contain;

            .topBox {
                position: relative;
                margin: 0 auto;
                width: 54.133vw;
                padding: 1px;
                box-sizing: border-box;
                margin-bottom: 8.533vw;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 54.133vw;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 36.267vw;
                    width: 36.267vw;
                    border-radius: 2.133vw;
                    margin: 14.933vw auto 0;
                    object-fit: cover;
                }

                .roomName {
                    position: relative;
                    width: 33.333vw;
                    font-size: 3.2vw;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin: 6.667vw auto 0;
                }

                .hotBox {
                    position: relative;
                    margin-top: 6.4vw;

                    .iconHot {
                        display: block;
                        height: 6.4vw;
                        width: 5.6vw;
                        margin-right: 2.133vw;
                    }

                    .hotNumber {
                        font-size: 4.267vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #DA123A;
                    }
                }
            }

            .top1Box {
                margin-top: 21.6vw;

                .iconWing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 100vw;
                    transform: translate(-50%, -67%);
                }
            }

            .list {
                height: 149.333vw;
                // overflow-y: scroll;
                margin: 0 5.333vw;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 0.533vw;
                    background: #FFFFFF;
                    // border-radius: 8px;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 0.533vw;
                    background: #F57891;
                    // border-radius: 8px;
                }

                .item {
                    // width: 85.333vw;
                    height: 21.333vw;
                    background: rgba(247, 133, 156, 0.5);

                    &.itemBg {
                        background: #F57891;
                    }

                    .sortNum {
                        width: 8.533vw;
                        height: 8.533vw;
                        line-height: 8.533vw;
                        text-align: center;
                        background: #FFEEF1;
                        border-radius: 50%;
                        font-size: 5.333vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #441B08;
                        margin-left: 3.733vw;
                    }

                    .roomImg {
                        display: block;
                        width: 17.067vw;
                        height: 17.067vw;
                        border-radius: 2.133vw;
                        margin-left: 5.867vw;
                        margin-right: 3.2vw;
                        object-fit: cover;
                    }

                    .roomName {
                        width: 44vw;
                        font-size: 3.2vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }

                    .hotBox {
                        margin-top: 2.4vw;

                        .iconHot {
                            height: 4.267vw;
                            width: 3.733vw;
                            margin-right: 1.067vw;
                        }

                        .hotNumber {
                            font-size: 3.2vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .ruleBox,
            .rewardBox {
                padding-top: 10.133vw;

                .title {
                    width: 42.933vw;
                    height: 13.333vw;
                    line-height: 10.667vw;
                    text-align: center;
                    background: #F57891;
                    border-radius: 6.533vw;
                    border: 1.333vw solid #FFD9E5;
                    margin: 0 auto;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 5.333vw;
                    color: #FFFFFF;
                    font-style: normal;
                    box-sizing: border-box;
                }

                .content {
                    margin: 2.133vw auto 0;
                    width: 92.533vw;
                    background: #F57891;
                    border-radius: 8vw;
                    border: 1.333vw solid #FFD9E5;
                    padding: 5.867vw 4.267vw;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 3.733vw;
                    color: #FFFFFF;
                    line-height: 8vw;
                    font-style: normal;
                    box-sizing: border-box;
                }
            }

            .rewardBox {
                padding-top: 12.8vw;
            }

            .descBox {
                width: 86.933vw;
                font-size: 3.2vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #280202;
                margin: 0 auto;
                text-align: center;
                padding-top: 10vw;
                padding-bottom: 10vw;
            }
        }
    }
}
</style>
