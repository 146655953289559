var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    !_vm.isLoading && !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1200px" } },
          [
            _c("img", {
              staticClass: "bg_activity",
              attrs: {
                src: require("../../assets/imgs/activity/20240308/pc_bg.jpg"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "content" }, [
              _vm.rankList[0]
                ? _c("div", { staticClass: "top1Box" }, [
                    _c("img", {
                      staticClass: "iconWing",
                      attrs: {
                        src: require("../../assets/imgs/activity/20240308/icon_wing.png"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      staticClass: "topBg",
                      attrs: {
                        src: require("../../assets/imgs/activity/20240308/pc_TOP1.png"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      staticClass: "roomImg",
                      attrs: { src: _vm.rankList[0].imgUrl, alt: "" },
                    }),
                    _c("div", { staticClass: "roomName d-overflow" }, [
                      _vm._v(_vm._s(_vm.rankList[0].nickName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "hotBox d-flex a-center j-center" },
                      [
                        _c("img", {
                          staticClass: "iconHot",
                          attrs: {
                            src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "hotNumber" }, [
                          _vm._v(_vm._s(_vm.rankList[0].diamond)),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "d-flex a-center j-center" }, [
                _vm.rankList[1]
                  ? _c("div", { staticClass: "top2Box" }, [
                      _c("img", {
                        staticClass: "topBg",
                        attrs: {
                          src: require("../../assets/imgs/activity/20240308/pc_TOP2.png"),
                          alt: "",
                        },
                      }),
                      _c("img", {
                        staticClass: "roomImg",
                        attrs: { src: _vm.rankList[1].imgUrl, alt: "" },
                      }),
                      _c("div", { staticClass: "roomName d-overflow" }, [
                        _vm._v(_vm._s(_vm.rankList[1].nickName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "hotBox d-flex a-center j-center" },
                        [
                          _c("img", {
                            staticClass: "iconHot",
                            attrs: {
                              src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "hotNumber" }, [
                            _vm._v(_vm._s(_vm.rankList[1].diamond)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.rankList[2]
                  ? _c("div", { staticClass: "top3Box" }, [
                      _c("img", {
                        staticClass: "topBg",
                        attrs: {
                          src: require("../../assets/imgs/activity/20240308/pc_TOP3.png"),
                          alt: "",
                        },
                      }),
                      _c("img", {
                        staticClass: "roomImg",
                        attrs: { src: _vm.rankList[2].imgUrl, alt: "" },
                      }),
                      _c("div", { staticClass: "roomName d-overflow" }, [
                        _vm._v(_vm._s(_vm.rankList[2].nickName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "hotBox d-flex a-center j-center" },
                        [
                          _c("img", {
                            staticClass: "iconHot",
                            attrs: {
                              src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "hotNumber" }, [
                            _vm._v(_vm._s(_vm.rankList[2].diamond)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.rankList.length > 3
                ? _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.rankList, function (item, index) {
                      return _c("div", { key: index }, [
                        index >= 3
                          ? _c(
                              "div",
                              {
                                class: [
                                  "item d-flex a-center",
                                  { itemBg: (index + 1) % 2 == 0 },
                                ],
                              },
                              [
                                _c("div", { staticClass: "sortNum" }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                                _c("img", {
                                  staticClass: "roomImg",
                                  attrs: { src: item.imgUrl, alt: "" },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "roomName d-overflow flex-1" },
                                  [_vm._v(_vm._s(item.nickName))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "hotBox d-flex a-center j-center",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconHot",
                                      attrs: {
                                        src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "hotNumber" }, [
                                      _vm._v(_vm._s(item.diamond)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._m(0),
              _vm._m(1),
              _vm._m(2),
            ]),
          ]
        )
      : _vm._e(),
    !_vm.isLoading && _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/20240308/h5_bg_top.jpg"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "content" }, [
            _vm.rankList[0]
              ? _c("div", { staticClass: "top1Box topBox" }, [
                  _c("img", {
                    staticClass: "iconWing",
                    attrs: {
                      src: require("../../assets/imgs/activity/20240308/icon_wing.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "topBg",
                    attrs: {
                      src: require("../../assets/imgs/activity/20240308/pc_TOP1.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomImg",
                    attrs: { src: _vm.rankList[0].imgUrl, alt: "" },
                  }),
                  _c("div", { staticClass: "roomName d-overflow" }, [
                    _vm._v(_vm._s(_vm.rankList[0].nickName)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "hotBox d-flex a-center j-center" },
                    [
                      _c("img", {
                        staticClass: "iconHot",
                        attrs: {
                          src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hotNumber" }, [
                        _vm._v(_vm._s(_vm.rankList[0].diamond)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.rankList[1]
              ? _c("div", { staticClass: "top2Box topBox" }, [
                  _c("img", {
                    staticClass: "topBg",
                    attrs: {
                      src: require("../../assets/imgs/activity/20240308/pc_TOP2.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomImg",
                    attrs: { src: _vm.rankList[1].imgUrl, alt: "" },
                  }),
                  _c("div", { staticClass: "roomName d-overflow" }, [
                    _vm._v(_vm._s(_vm.rankList[1].nickName)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "hotBox d-flex a-center j-center" },
                    [
                      _c("img", {
                        staticClass: "iconHot",
                        attrs: {
                          src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hotNumber" }, [
                        _vm._v(_vm._s(_vm.rankList[1].diamond)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.rankList[2]
              ? _c("div", { staticClass: "top3Box topBox" }, [
                  _c("img", {
                    staticClass: "topBg",
                    attrs: {
                      src: require("../../assets/imgs/activity/20240308/pc_TOP3.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomImg",
                    attrs: { src: _vm.rankList[2].imgUrl, alt: "" },
                  }),
                  _c("div", { staticClass: "roomName d-overflow" }, [
                    _vm._v(_vm._s(_vm.rankList[2].nickName)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "hotBox d-flex a-center j-center" },
                    [
                      _c("img", {
                        staticClass: "iconHot",
                        attrs: {
                          src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hotNumber" }, [
                        _vm._v(_vm._s(_vm.rankList[2].diamond)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.rankList.length > 3
              ? _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(_vm.rankList, function (item, index) {
                    return _c("div", { key: index }, [
                      index >= 3
                        ? _c(
                            "div",
                            {
                              class: [
                                "item d-flex a-center",
                                { itemBg: (index + 1) % 2 == 0 },
                              ],
                            },
                            [
                              _c("div", { staticClass: "sortNum" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c("img", {
                                staticClass: "roomImg",
                                attrs: { src: item.imgUrl, alt: "" },
                              }),
                              _c("div", { staticClass: "right" }, [
                                _c(
                                  "div",
                                  { staticClass: "roomName d-overflow" },
                                  [_vm._v(_vm._s(item.nickName))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "hotBox d-flex a-center" },
                                  [
                                    _c("img", {
                                      staticClass: "iconHot",
                                      attrs: {
                                        src: require("../../assets/imgs/activity/20240308/icon_hot.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "hotNumber" }, [
                                      _vm._v(_vm._s(item.diamond)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动规则")]),
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(" 1. 活动时间：2024年3月8日至3月10日。"),
          _c("br"),
          _vm._v(" 2. 活动期间，用户每收到1随心钻价值礼物可获得1点魅力值。"),
          _c("br"),
          _vm._v(" 3. 魅力值将根据用户收到的礼物价值进行累积。"),
          _c("br"),
          _vm._v(" 4. 活动结束后，根据用户的魅力值进行排名，并公布排名结果。"),
          _c("br"),
          _vm._v(" 5. 奖励会在活动结束后14个工作日内发放。 "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rewardBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动奖励")]),
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(
            " 1.排名第1名：获得独家宠爱(5200钻)，新春Q头(30天)，粉色之恋(30天)。"
          ),
          _c("br"),
          _vm._v(
            " 2.排名第2名：获得心之声(3344钻)，新春Q头(14天)，粉色之恋(14天)。"
          ),
          _c("br"),
          _vm._v(
            " 3.排名第3名：获得四季予你(1314钻)，新春Q头(7天)，粉色之恋(7天)。"
          ),
          _c("br"),
          _vm._v(
            " 4.排名第4-10名：获得守护神(520钻)，新春Q头(3天)，粉色之恋(3天)。"
          ),
          _c("br"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _vm._v(
        " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
      ),
      _c("br"),
      _vm._v(" 如有疑问请在公众号“随心开黑”联系在线客服进行咨询。 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动规则")]),
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(" 1. 活动时间：2024年3月8日至3月10日。"),
          _c("br"),
          _vm._v(" 2. 活动期间，用户每收到1随心钻价值礼物可获得1点魅力值。"),
          _c("br"),
          _vm._v(" 3. 魅力值将根据用户收到的礼物价值进行累积。"),
          _c("br"),
          _vm._v(" 4. 活动结束后，根据用户的魅力值进行排名，并公布排名结果。"),
          _c("br"),
          _vm._v(" 5. 奖励会在活动结束后14个工作日内发放。 "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rewardBox" }, [
      _c("div", { staticClass: "title" }, [_vm._v("活动奖励")]),
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(
            " 1.排名第1名：获得独家宠爱(5200钻)，新春Q头(30天)，粉色之恋(30天)。"
          ),
          _c("br"),
          _vm._v(
            " 2.排名第2名：获得心之声(3344钻)，新春Q头(14天)，粉色之恋(14天)。"
          ),
          _c("br"),
          _vm._v(
            " 3.排名第3名：获得四季予你(1314钻)，新春Q头(7天)，粉色之恋(7天)。"
          ),
          _c("br"),
          _vm._v(
            " 4.排名第4-10名：获得守护神(520钻)，新春Q头(3天)，粉色之恋(3天)。"
          ),
          _c("br"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _vm._v(
        " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
      ),
      _c("br"),
      _vm._v(" 如有疑问请在公众号“随心开黑”联系在线客服进行咨询。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }